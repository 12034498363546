/* eslint-disable */
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import {
  Router,
  Route,
  IndexRoute,
  browserHistory,
  withRouter,
} from 'react-router';
import jwtDecode from 'jwt-decode';
import { Provider } from 'react-redux';
import _ from 'lodash';
import ReactGA from 'react-ga';

// Import actions.
import { fetchAgreements, setCurrentUser } from './actions/authActions';

// Import helpers.
import requireAuth from './utils/requireAuth';
import withTracker from './utils/withTracker';

// Redux store
import store from './store';

// Applicaton entry
import App from './components/App';

import { acl } from './config';

// Page components
import LoginPage from './components/Login/LoginPage';
import LandingPage from './components/Landing/LandingPage';
import HelpPage from './components/Help';
import HelpCategoryPage from './components/Help/HelpCategoryPage';
import HelpPostCreatePage from './components/Help/HelpPostCreatePage';
import HelpPostPage from './components/Help/HelpPostPage';
import WorkbenchPage from './components/Workbench/WorkbenchPage';
import ReviewsPage from './components/Reviews/ReviewsPage';
import ReviewsDetailPage from './components/Reviews/ReviewDetailPage';
import ProfilePage from './components/Profile/ProfilePage';
import ReviewDetailPage from './components/Review/ReviewDetailPage';
import EASReviewDetailPage from './components/Review/ReviewDetailPage';
import ReviewCreateForm from './components/Review/ReviewCreateForm';
import Search from './components/Search/Search';
import NotFound from './components/NotFound';
import ClassReport from './components/Reviews/ClassReport';
import CalendarPage from './components/Calendar/CalendarPage';
import Survey from './components/Survey/Survey';
import TaskTable from './components/Tasks/TaskTable';
import TaskPage from './components/Tasks/TaskPage';
import GranteePage from './components/Grantee/GranteePage';
import GranteeList from './components/Grantee/GranteeList';
import GranteeDetailPage from './components/Grantee/GranteeDetailPage';
import MyRegionalReviewsPage from './components/MyRegionalReviews/MyRegionalReviewsPage';
import ReviewPage from './components/Review/ReviewPage';
import ReportPage from './components/OldReports/ReportPage';
import ReportDetail from './components/Reports/ReportDetail';
import Unauthorized from './components/Shared/Unauthorized';
import AuthPage from './utils/AuthPage';
import AdminPage from './components/Admin/AdminPage';
import TaskAdminPage from './components/Admin/TaskAdminPage';
import SurveyAdminPage from './components/Admin/SurveyAdminPage';
import ReportAdminPage from './components/Admin/ReportAdminPage';
import UsersAdminPage from './components/Admin/UsersAdminPage';
import TemplateAdminPage from './components/Admin/TemplateAdminPage';
import ParametersAdminPage from './components/Admin/ParametersAdminPage';
import FilesAdminPage from './components/Admin/FilesAdminPage';
import SurveyOutcome from './components/Survey/SurveyOutcome';
import ReportComment from './components/OldReports/ReportComment';
import PlanningPage from './components/Planning/PlanningPage';
import UserProfilePage from './components/Admin/UserProfilePage';
import ReviewsOCISummaryPage from './components/Review/FY19/ReviewOCISummary';
import ReviewManifestPage from './components/Review/FY19/ReviewManifest';
import ReviewsFindingsPage from './components/Review/ReviewsFindingsPage';
import ReviewsFA2CenterAndExplorationSummary from './components/Review/ReviewsFA2CenterAndExplorationSummary';
import ReviewEvidenceBinderPage from './components/Review/FY19/ReviewEvidenceFiles';
import ReviewJournalPage from './components/Review/FY19/ReviewJournalFiles';
import FollowUpRecommendations from './components/FollowUpRecommendations';
import FollowUpRecommendationsLegacy from './components/FollowUpRecommendations/legacy';
import ReviewPlanFollowUpPage from './components/Review/PlanFollowup/index';
import ReviewsFollowUpRecommendationsPage from './components/Review/ReviewsFollowUpRecommendationsPage';
import ReviewsFollowUpRecommendationsCommentsPage from './components/Review/ReviewsFollowUpRecommendationsCommentsPage';
import CLASSSurveyPage from './components/Survey/CLASSSurveyPage';
import CLASSReplacementForm from './components/Survey/CLASSReplacementForm';
import CLASSCenterAssignments from './components/Survey/CLASSCenterAssignments';
import CLASSDualCoderComparison from './components/Survey/CLASSDualCoderComparison';
import CLASSMethodology from './components/Survey/CLASSMethodology';
import CLASSPassFailReport from './components/Survey/CLASSPassFailReport';
import StepTest from './components/StepTest';
import FollowupSurveyPage from './components/Survey/FollowupSurveyPage';
import ReviewCreatePage from './components/Review/ReviewCreatePage';
import InvitationPage from './components/Invitation/InvitationPage';
import FY19ReviewDetailPage from './components/Review/FY19/ReviewDetailPage';
import FA1Survey from './components/Survey/FA1Survey';
import FA2Survey from './components/Survey/FA2Survey';
import RANSurveyPaths from './components/Survey/RANSurveyNew/RANSurveyPaths';
import PresiteSurvey from './components/Survey/PresiteSurvey';
import ReviewCitationsPage from './components/Review/ReviewCitationsPage';
import ChildFileOutcome from './components/Survey/SurveyOutcomes/ChildFileOutcome';
import ChildFileOutcomeNew from './components/Survey/SurveyOutcomesNew/ChildFileOutcomeNew';
import ChildFileOutcomePaths from './components/Survey/SurveyOutcomesNew/ChildFileOutcomePaths';
import GranteeExtension from './components/Grantee/GranteeExtension';
import GranteeViewAvailability from './components/Grantee/GranteeViewAvailability';
import FollowUpSurvey from './components/Survey/FollowUpSurvey';
import FollowUpSurveyPaths from './components/Survey/FollowUpSurveyNew/FollowUpSurveyPaths';
import SchedulingGranteeView from './components/Grantee/SchedulingGranteeView';
import ScheduleView from './components/Grantee/SchedulerViewGranteeAvailability/ScheduleView';
import HSESWebService from './components/OldReports/HSESWebService';

import ReviewFollowUps from './components/Review/Followup';
import ExternalLinkPage from './utils/ExternalLinkPage';
import GranteeExtensionRequest from './components/Grantee/GranteeExtensionRequest';
import GranteeAvailability from './components/GranteeAvailability';
import GranteeDetailAvailability from './components/GranteeAvailability/GranteeDetailAvailability';
import GranteeDetailScheduleView from './components/GranteeAvailability/GranteeDetailAvailability/GranteeDetailScheduleView';
import GranteeDetail from './components/GranteeAvailability/GranteePage';
import GranteeExtensionHistory from './components/GranteeAvailability/GranteePage/GranteeExtenstionHistory';
import LogsPage from './components/Admin/LogsPage';
import CreateReviewPage from './components/Review/CreateReview/CreateReviewPage';
import AgreementsPage from './utils/AgreementsPage';
import ReportTrackerFindingsPage from './components/Tasks/ReportTrackerFindingsPage';
import SurveyReportTrackerFindingsPage from './components/Tasks/SurveyReportTrackerFindingsPage';
import EASSurveyForm from './components/Survey/EASDataCollection';
import SurveyQuestions from './components/Admin/SurveyQuestions/SurveyQuestions';
import SurveyQuestionsAdminPage from './components/Admin/SurveyQuestions';
import ConsolidatedQuestions from './components/Admin/SurveyQuestions/ConsolidatedQuestions';
import FindingPaths from './components/Review/Findings/FindingPaths';
import FindingsPage from './components/Review/Findings/FindingsPage';
import EvidenceBinderPaths from './components/Review/FY19/ReviewEvidenceFiles/EvidenceBinderPaths';
import ReviewManagementAnalysisReportPage from './components/Review/FY19/ReviewManagementAnalysisReport';
import PreReviewSummaryReportPage from './components/Review/FY19/PreReviewSummaryReport';
import SurveyAllReportTrackerFindingsPage from './components/Tasks/SurveyAllReportTrackerFindingsPage';
import ReviewsCenterClassroomManagement from './components/Review/ReviewsCenterClassroomManagement';

if (localStorage.token) {
  store.dispatch(setCurrentUser(jwtDecode(localStorage.token)));
  store.dispatch(fetchAgreements());
} else {
  store.dispatch(setCurrentUser({}));
}

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
const env = process.env.NODE_ENV;

if (trackingId) {
  ReactGA.initialize(trackingId, {
    debug:
      (env === 'development' ||
        env === 'devnew' ||
        env === 'dev' ||
        env === 'test',
      env === 'stable',
      env === 'stablenew'), // Only enable debug mode in dev test and staging environments.
    titleCase: false,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <Router
      onUpdate={() => {
        document.getElementById('root').focus();
        window.scrollTo({
          top: 0,
          left: 0,
        });
      }}
      history={browserHistory}
    >
      <Route name="Steps" path="steps" component={StepTest} />
      <Route path="login" component={withTracker(LoginPage)} />
      <Route path="auth" component={withTracker(AuthPage)} />
      <Route path="add-comment" component={withTracker(ReportComment)} />
      <Route
        path="no-auth/final-report/:guid"
        component={withTracker(HSESWebService)}
      />
      <Route
        path="grantee-unavailability"
        component={withTracker(GranteeAvailability)}
      />
      <Route
        path="grantee-extension"
        component={withTracker(GranteeExtension)}
      />
      <Route
        name="Outcome"
        path="review/:id/survey-outcome/:koboFormId/:category"
        getDisplayName={param =>
          ` ${param.id} / Survey Outcome / ${_.startCase(
            param.category.split('-').join(' ')
          )}`
        }
        component={withTracker(requireAuth(SurveyOutcome, acl.routes.review))}
      />
      <Route path="external-link" component={withTracker(ExternalLinkPage)} />
      <Route
        name="Agreements Page"
        path="agreements"
        component={withTracker(requireAuth(AgreementsPage))}
      />

      {/* <Route
        name="New Survey Outcome"
        path="review/fy19/:id/survey-outcome/:amsSubmissionId"
      >
        <Route
          path="child-files"
          getDisplayName={param => `Child Files`}
          component={withTracker(
            requireAuth(ChildFileOutcome, acl.routes.review)
          )}
        />
      </Route>
      <Route
        name="New Survey Outcome"
        path="review/:fiscalYear/:id/survey-outcome/:amsSubmissionId"
      >
        <Route
          path="child-files"
          getDisplayName={param => `Child Files`}
          component={withTracker(
            requireAuth(ChildFileOutcome, acl.routes.review)
          )}
        />
      </Route>
      <Route
        name="Reviews"
        path="review/fy19/:id/performance-rating-summary"
        component={withTracker(requireAuth(ReviewsOCISummaryPage))}
      />
      <Route
        name="Reviews"
        path="review/:fiscalYear/:id/performance-rating-summary"
        component={withTracker(requireAuth(ReviewsOCISummaryPage))}
      />
      <Route
        name="Reviews"
        path="review/:id/findings"
        component={withTracker(requireAuth(ReviewsFindingsPage))}
      />
      <Route
        name="Reviews"
        path="review/fy19/:id/findings"
        component={withTracker(requireAuth(ReviewsFindingsPage))}
      />
      <Route
        name="Reviews"
        path="review/:fiscalYear/:id/findings"
        component={withTracker(requireAuth(ReviewsFindingsPage))}
      />

      <Route
        name="Reviews"
        path="review/:id/follow-up-recommendations-comments"
        component={withTracker(
          requireAuth(ReviewsFollowUpRecommendationsCommentsPage)
        )}
      />
      <Route
        name="Reviews"
        path="review/fy19/:id/follow-up-recommendations-comments"
        component={withTracker(
          requireAuth(ReviewsFollowUpRecommendationsCommentsPage)
        )}
      />
      <Route
        name="Reviews"
        path="review/:fiscalYear/:id/follow-up-recommendations-comments"
        component={withTracker(
          requireAuth(ReviewsFollowUpRecommendationsCommentsPage)
        )}
      />
      <Route
        name="Reviews"
        path="review/:id/follow-up-recommendations"
        component={withTracker(requireAuth(FollowUpRecommendations))}
      />
      <Route
        name="Reviews"
        path="review/:id/follow-up-recommendations-legacy"
        component={withTracker(requireAuth(FollowUpRecommendationsLegacy))}
      />
      <Route
        name="Reviews"
        path="review/fy19/:id/follow-up-recommendations"
        component={withTracker(requireAuth(FollowUpRecommendations))}
      />
      <Route
        name="Reviews"
        path="review/:fiscalYear/:id/follow-up-recommendations"
        component={withTracker(requireAuth(ReviewsFollowUpRecommendationsPage))}
      />
      <Route
        name="Reviews"
        path="review/:id/follow-up-recommendations/:citationId"
        component={withTracker(requireAuth(ReviewsFollowUpRecommendationsPage))}
      />
      <Route
        name="Reviews"
        path="review/fy19/:id/follow-up-recommendations/:citationId"
        component={withTracker(requireAuth(ReviewsFollowUpRecommendationsPage))}
      />
      <Route
        name="Reviews"
        path="review/:fiscalYear/:id/follow-up-recommendations/:citationId"
        component={withTracker(requireAuth(ReviewsFollowUpRecommendationsPage))}
      />
      <Route
        name="Center-Assignments"
        path="review/:id/center-and-class-assignments"
        getDisplayName={param => `Center and Classroom Assignments`}
        component={withTracker(requireAuth(CLASSCenterAssignments))}
      />
      <Route
        name="Dual Coder Comparison Summary"
        path="review/:id/dual-comparison-summary"
        getDisplayName={param => `Dual Coder Comparison Summary`}
        component={withTracker(requireAuth(CLASSDualCoderComparison))}
      />
      <Route
        name="CLASS-Methodology"
        path="review/:id/class-methodology"
        getDisplayName={param => `Center and Classroom Assignments`}
        component={withTracker(requireAuth(CLASSMethodology))}
      />
      <Route
        name="Pass-Fail-Report"
        path="review/:id/pass-fail-report"
        getDisplayName={param => `Dual Coder Comparison Summary`}
        component={withTracker(requireAuth(CLASSPassFailReport))}
      /> */}

      {/* <Route name="Task" path="task/:reviewId/survey">
        <IndexRoute
          getDisplayName={param => `CLASS Survey`}
          component={withTracker(requireAuth(CLASSSurveyPage))}
        />
        <Route
          path="class"
          getDisplayName={param => `CLASS Survey`}
          component={withTracker(requireAuth(CLASSSurveyPage))}
        />
        <Route
          path="class-replacement"
          getDisplayName={param => `CLASS Replacement`}
          component={withTracker(requireAuth(CLASSReplacementForm))}
        />
        <Route
          path="follow-up"
          getDisplayName={param => `Follow-Up Survey`}
          component={withTracker(requireAuth(FollowUpSurvey))}
        />
        <Route
          path="fa1"
          getDisplayName={param => `FA1 Survey`}
          component={withTracker(requireAuth(FA1Survey))}
        />
        <Route
          path="fa2"
          getDisplayName={param => `FA2 Survey`}
          component={withTracker(requireAuth(FA2Survey))}
        />
        <Route
          path="special"
          getDisplayName={param => `FA2 Survey`}
          component={withTracker(requireAuth(FA2Survey))}
        />
        <Route
          path="presite"
          getDisplayName={param => `Presite`}
          component={withTracker(requireAuth(PresiteSurvey))}
        />
        <Route
          path="ran"
          getDisplayName={param => `RAN`}
          component={withTracker(requireAuth(RANSurvey))}
        />
        <Route
          path="aian-def"
          getDisplayName={param => `AIAN-DEF`}
          component={withTracker(requireAuth(FA2Survey))}
        />
      </Route> */}

      <Route path="/" name="Home" component={withTracker(requireAuth(App))}>
        <IndexRoute
          component={withTracker(requireAuth(LandingPage, acl.routes.home))}
        />
        <Route
          name="Help"
          path="help"
          component={withTracker(requireAuth(HelpPage, acl.routes.help))}
        />
        <Route
          name="Help"
          getDisplayName={param => `Create`}
          path="help/create"
          component={withTracker(
            requireAuth(HelpPostCreatePage, acl.routes.help)
          )}
        />
        <Route
          name="Help"
          path="help/:slug/:id"
          component={withTracker(requireAuth(HelpPostPage, acl.routes.help))}
        />
        <Route
          name="Help"
          getDisplayName={param => `Edit`}
          path="help/:slug/:id/edit"
          component={withTracker(
            requireAuth(HelpPostCreatePage, acl.routes.help)
          )}
        />
        <Route
          name="Help Category"
          path="help/:slug"
          component={withTracker(
            requireAuth(HelpCategoryPage, acl.routes.help)
          )}
        />
        <Route
          name="Workbench"
          path="workbench"
          component={withTracker(
            requireAuth(WorkbenchPage, acl.routes.workbench)
          )}
        />
        <Route
          name=""
          path="reviews"
          component={withTracker(requireAuth(ReviewsPage))}
        />
        <Route
          name="Reviews"
          path="reviews/:id"
          component={withTracker(requireAuth(ReviewsDetailPage))}
        />
        <Route
          name=""
          path="reviews/:id/report"
          component={withTracker(requireAuth(ClassReport))}
        />
        <Route
          name="Search"
          path="search"
          component={withTracker(requireAuth(Search))}
        />
        <Route
          name="Search"
          path="search/:searchType"
          getDisplayName={param =>
            `Search / ${param.searchType} / ${param.searchQuery}`
          }
          component={withTracker(requireAuth(Search))}
        />
        <Route
          name="Search"
          path="search/:searchType/:searchQuery"
          getDisplayName={param =>
            `Search / ${param.searchType} / ${param.searchQuery}`
          }
          component={withTracker(requireAuth(Search))}
        />
        <Route
          name="Search"
          path="search/:searchType/:searchQuery/:pageNumber"
          getDisplayName={param =>
            `Search / ${param.searchType} / ${param.searchQuery}`
          }
          component={withTracker(requireAuth(Search))}
        />
        <Route
          name="Profile"
          path="profile"
          component={withTracker(requireAuth(ProfilePage, acl.routes.profile))}
        />
        <Route
          name="Calendar"
          path="calendar"
          component={withTracker(
            requireAuth(CalendarPage, acl.routes.calendar)
          )}
        />
        <Route
          name="Invitations"
          path="invitations/:id"
          getDisplayName={params => `Invitations / Respond`}
          component={withTracker(InvitationPage)}
        />
        <Route
          name="Task"
          path="task"
          component={withTracker(requireAuth(TaskPage, acl.routes.task))}
        />
        <Route
          name="Report"
          path="report"
          component={withTracker(requireAuth(ReportPage, acl.routes.report))}
        />
        <Route
          name="Reports"
          path="reports"
          path="reports/:id"
          component={withTracker(requireAuth(ReportDetail, acl.routes.report))}
        />

        <Route name="Review" path="review">
          <IndexRoute
            component={withTracker(requireAuth(ReviewPage, acl.routes.review))}
          />
          {/* <Route
            name="Create"
            path="create"
            component={withTracker(
              requireAuth(ReviewCreatePage, acl.actions.review.create)
            )}
            getDisplayName={param => `Create`}
          /> */}
          <Route
            name="Create"
            path="create"
            component={withTracker(
              requireAuth(CreateReviewPage, acl.actions.review.create)
            )}
            getDisplayName={param => `Create`}
          />
          <Route name="Detail" path=":id">
            <IndexRoute
              component={withTracker(
                requireAuth(ReviewDetailPage, acl.routes.review)
              )}
              // getDisplayName={param => `Detail`}
            />
            <Route
              name="Center-Assignments"
              path="center-and-class-assignments"
              getDisplayName={param => `Center and Classroom Assignments`}
              component={withTracker(requireAuth(CLASSCenterAssignments))}
            />
            <Route
              name="Dual Coder Comparison Summary"
              path="dual-comparison-summary"
              getDisplayName={param => `Dual Coder Comparison Summary`}
              component={withTracker(requireAuth(CLASSDualCoderComparison))}
            />
            <Route
              name="CLASS-Methodology"
              path="class-methodology"
              getDisplayName={param => `CLASS Methodology`}
              component={withTracker(requireAuth(CLASSMethodology))}
            />
            <Route
              name="Pass-Fail-Report"
              path="pass-fail-report"
              getDisplayName={param => `Pass/Fail Report`}
              component={withTracker(requireAuth(CLASSPassFailReport))}
            />
            <Route
              name="Center-Assignments"
              path="center-and-class-assignments"
              getDisplayName={param => `Center and Classroom Assignments`}
              component={withTracker(requireAuth(CLASSCenterAssignments))}
            />
            <Route
              name="Dual Coder Comparison Summary"
              path="dual-comparison-summary"
              getDisplayName={param => `Dual Coder Comparison Summary`}
              component={withTracker(requireAuth(CLASSDualCoderComparison))}
            />
            <Route
              name="CLASS-Methodology"
              path="class-methodology"
              getDisplayName={param => `CLASS Methodology`}
              component={withTracker(requireAuth(CLASSMethodology))}
            />
            <Route
              name="Pass-Fail-Report"
              path="pass-fail-report"
              getDisplayName={param => `Pass/Fail Report`}
              component={withTracker(requireAuth(CLASSPassFailReport))}
            />
            <Route
              name="Follow-Up-Recommendations-Comments"
              path="follow-up-recommendations-comments"
              component={withTracker(
                requireAuth(ReviewsFollowUpRecommendationsCommentsPage)
              )}
            />
            <Route
              path="plan-follow-up"
              component={withTracker(requireAuth(ReviewPlanFollowUpPage))}
              getDisplayName={param => `Plan Follow-up Review Team`}
            />
            <Route
              name="Follow-Up-Recommendations"
              path="follow-up-recommendations"
              component={withTracker(requireAuth(FollowUpRecommendations))}
            />
            <Route
              name="Follow-Up-Recommendations"
              path="follow-up-recommendations-legacy"
              component={withTracker(
                requireAuth(FollowUpRecommendationsLegacy)
              )}
            />
            <Route
              name="Reviews"
              path="manifest"
              getDisplayName={param => `Manifest`}
              component={withTracker(requireAuth(ReviewManifestPage))}
            />
            <Route
              name="Reviews"
              path="evidence-binder"
              getDisplayName={param => `Evidence Binder`}
              component={withTracker(requireAuth(EvidenceBinderPaths))}
            />
            <Route
              name="Reviews"
              path="review-journal"
              getDisplayName={param => `Journal`}
              component={withTracker(requireAuth(ReviewJournalPage))}
            />
            <Route
              path="edit"
              getDisplayName={param => `Edit`}
              component={withTracker(
                requireAuth(CreateReviewPage, acl.actions.review.update)
              )}
            />
          </Route>
          <Route name="Citations" path="citations/definition">
            <IndexRoute
              component={withTracker(
                requireAuth(ReviewCitationsPage, acl.routes.review)
              )}
              getDisplayName={param => `Citations`}
            />
          </Route>
          <Route name="Detail" path=":fiscalYear">
            <Route name="Detail" path=":reviewId">
              <IndexRoute
                component={withTracker(
                  requireAuth(FY19ReviewDetailPage, acl.routes.review)
                )}
                // getDisplayName={param => `Detail`}
              />
              <Route
                name="Pass-Fail-Report"
                path="pass-fail-report"
                getDisplayName={param => `Pass/Fail Report`}
                component={withTracker(requireAuth(CLASSPassFailReport))}
              />
              <Route
                path="edit"
                getDisplayName={param => `Edit`}
                component={withTracker(requireAuth(ReviewCreatePage))}
              />
            </Route>
          </Route>
          <Route name="Create" path="new/create">
            <IndexRoute
              component={withTracker(
                requireAuth(ReviewCreateForm, acl.actions.review.create)
              )}
              getDisplayName={param => `Create`}
            />
          </Route>
          <Route name="Detail" path="new/:fiscalYear">
            <Route name="Detail" path=":id">
              <IndexRoute
                component={withTracker(
                  requireAuth(EASReviewDetailPage, acl.routes.review)
                )}
              />
            </Route>
          </Route>
        </Route>

        <Route name="Grantee" path="grantee">
          <IndexRoute
            component={withTracker(
              requireAuth(GranteePage, acl.routes.grantee)
            )}
          />
          <Route name="Detail" path=":id">
            <IndexRoute
              component={withTracker(
                requireAuth(GranteeDetailPage, acl.routes.grantee)
              )}
            />
            <Route
              name="Availability"
              path="availability"
              getDisplayName={param => `Availability`}
              component={withTracker(
                requireAuth(GranteeDetailAvailability, acl.routes.grantee)
              )}
            />
            <Route
              name="Schedule View"
              path="availability/schedule/:fiscalYear"
              getDisplayName={param => `Availability Schedule View`}
              component={withTracker(
                requireAuth(GranteeDetailScheduleView, acl.routes.grantee)
              )}
            />
            <Route
              name="Extension Requests"
              path="availability/extension-request"
              getDisplayName={param => `Extension Requests`}
              component={withTracker(
                requireAuth(GranteeExtensionRequest, acl.routes.grantee)
              )}
            />
            <Route
              name="Schedule Extensions"
              path="availability/schedule-extensions"
              getDisplayName={param => `Schedule Extensions`}
              component={withTracker(
                requireAuth(GranteeExtensionHistory, acl.routes.grantee)
              )}
            />
          </Route>
        </Route>

        <Route
          name="MyRegionalReviews"
          path="myregionalreviews"
          component={withTracker(
            requireAuth(MyRegionalReviewsPage, acl.routes.myregionalreviews)
          )}
        />

        <Route
          name="Administration"
          path="admin"
          component={withRouter(requireAuth(AdminPage))}
        >
          <IndexRoute
            component={withTracker(
              requireAuth(TaskAdminPage, acl.routes.admin.tasks)
            )}
          />

          <Route name="Tasks" path="tasks">
            <IndexRoute
              component={withTracker(
                requireAuth(TaskAdminPage, acl.routes.admin.tasks)
              )}
            />
            <Route
              path=":action"
              name="Create"
              component={withTracker(
                requireAuth(TaskAdminPage, acl.routes.admin.tasks)
              )}
            />
          </Route>

          <Route
            path="surveys"
            getDisplayName={param => `Surveys`}
            component={withTracker(
              requireAuth(SurveyAdminPage, acl.routes.admin.surveys)
            )}
          />

          <Route
            path="reports"
            getDisplayName={param => `Reports`}
            component={withTracker(
              requireAuth(ReportAdminPage, acl.routes.admin.reports)
            )}
          />

          <Route name="Users" path="users">
            <IndexRoute
              component={withTracker(
                requireAuth(UsersAdminPage, acl.routes.admin.users)
              )}
            />
            <Route
              path=":action"
              name="Create"
              component={withTracker(
                requireAuth(UsersAdminPage, acl.routes.admin.users)
              )}
            />
            <Route
              path="profile/:userId"
              getDisplayName={param => param.userId && 'Profile'}
              component={withTracker(
                requireAuth(UserProfilePage, acl.routes.admin.users)
              )}
            />
          </Route>

          <Route
            path="templates"
            getDisplayName={param => `Templates`}
            component={withTracker(
              requireAuth(TemplateAdminPage, acl.routes.admin.templates)
            )}
          />
          <Route
            path="files"
            getDisplayName={param => `Files`}
            component={withTracker(
              requireAuth(FilesAdminPage, acl.routes.admin.templates)
            )}
          />
          <Route
            path="parameters"
            getDisplayName={param => `Parameters`}
            component={withTracker(
              requireAuth(ParametersAdminPage, acl.routes.admin.parameters)
            )}
          />
          <Route
            path="logs"
            getDisplayName={param => `Logs`}
            component={withTracker(
              requireAuth(LogsPage, acl.routes.admin.parameters)
            )}
          />
          <Route
            path="surveyquestions"
            getDisplayName={param => `SurveyQuestions`}
            component={withTracker(
              requireAuth(SurveyQuestionsAdminPage, acl.routes.admin.parameters)
            )}
          />
          <Route
            path="surveyquestions/create"
            getDisplayName={param => `SurveyQuestions`}
            component={withTracker(
              requireAuth(SurveyQuestions, acl.routes.admin.parameters)
            )}
          />
          <Route
            path="surveyquestions/view"
            getDisplayName={param => `SurveyQuestions`}
            component={withTracker(
              requireAuth(ConsolidatedQuestions, acl.routes.admin.parameters)
            )}
          />
          <Route
            path="surveyquestions/:id/edit"
            getDisplayName={param => `Edit`}
            component={withTracker(
              requireAuth(SurveyQuestions, acl.routes.admin.parameters)
            )}
          />
        </Route>

        <Route
          name="Planning"
          path="planning"
          component={withTracker(
            requireAuth(PlanningPage, acl.routes.planning)
          )}
        />

        <Route
          name="Unauthorized"
          path="unauthorized"
          component={withTracker(Unauthorized)}
        />
        <Route
          path="grantee-unavailability"
          component={withTracker(GranteeAvailability)}
        />
        <Route
          path="grantee-extension"
          component={withTracker(GranteeExtension)}
        />
        <Route
          name="Outcome"
          path="review/:id/survey-outcome/:koboFormId/:category"
          getDisplayName={param =>
            ` ${param.id} / Survey Outcome / ${_.startCase(
              param.category.split('-').join(' ')
            )}`
          }
          component={withTracker(requireAuth(SurveyOutcome, acl.routes.review))}
        />
        <Route
          name="New Survey Outcome"
          p6ath="review/fy19/:id/survey-outcome/:amsSubmissionId"
        >
          <Route
            path="child-files"
            getDisplayName={param => `Eligibility File Submission Results`}
            component={withTracker(
              requireAuth(ChildFileOutcome, acl.routes.review)
            )}
          />
        </Route>
        <Route
          name="Data-Collections"
          path="review/:fiscalYear/:id/survey/fa2cr"
          getDisplayName={param => `Data Collections`}
          component={withTracker(requireAuth(EASSurveyForm))}
        />
        <Route
          name="Data-Collections"
          path="review/:fiscalYear/:id/survey/fa1fr"
          getDisplayName={param => `Data Collections`}
          component={withTracker(requireAuth(EASSurveyForm))}
        />
        <Route
          name="Data-Collections"
          path="review/:fiscalYear/:id/survey/ran"
          getDisplayName={param => `Data Collections`}
          component={withTracker(requireAuth(EASSurveyForm))}
        />
        <Route
          name="Data-Collections"
          path="review/:fiscalYear/:id/survey/followup"
          getDisplayName={param => `Data Collections`}
          component={withTracker(requireAuth(EASSurveyForm))}
        />
        <Route
          name="Data-Collections"
          path="review/:fiscalYear/:id/survey/special"
          getDisplayName={param => `Data Collections`}
          component={withTracker(requireAuth(EASSurveyForm))}
        />
        <Route
          name="Reviews"
          path="review/:id/findings"
          getDisplayName={param => `Findings`}
          component={withTracker(requireAuth(ReviewsFindingsPage))}
        />
        <Route
          name="Reviews"
          path="review/fy19/:id/findings"
          getDisplayName={param => `Findings`}
          component={withTracker(requireAuth(ReviewsFindingsPage))}
        />
        <Route
          name="Reviews"
          path="review/:id/follow-up-recommendations-comments"
          component={withTracker(
            requireAuth(ReviewsFollowUpRecommendationsCommentsPage)
          )}
        />
        <Route
          name="Reviews"
          path="review/fy19/:id/follow-up-recommendations-comments"
          component={withTracker(
            requireAuth(ReviewsFollowUpRecommendationsCommentsPage)
          )}
        />
        <Route
          name="Reviews"
          path="review/:id/follow-up-recommendations"
          component={withTracker(requireAuth(FollowUpRecommendations))}
        />
        <Route
          name="Data-Collections"
          path="review/:fiscalYear/:id/survey/fa2cr"
          getDisplayName={param => `Data Collections`}
          component={withTracker(requireAuth(EASSurveyForm))}
        />
        <Route
          name="Reviews"
          path="review/:id/follow-up-recommendations-legacy"
          component={withTracker(requireAuth(FollowUpRecommendationsLegacy))}
        />
        <Route
          name="Reviews"
          path="review/fy19/:id/follow-up-recommendations"
          component={withTracker(requireAuth(FollowUpRecommendations))}
        />
        <Route
          name="Reviews"
          path="review/:id/follow-up-recommendations/:citationId"
          component={withTracker(requireAuth(FollowUpRecommendations))}
        />
        <Route
          name="Reviews"
          path="review/fy19/:id/follow-up-recommendations/:citationId"
          component={withTracker(requireAuth(FollowUpRecommendations))}
        />

        <Route
          name="New Survey Outcome"
          path="review/fy19/:id/survey-outcome/:amsSubmissionId/:amsFormId"
        >
          <Route
            path="child-files"
            getDisplayName={param => `Eligibility File Submission Results`}
            component={withTracker(
              requireAuth(ChildFileOutcome, acl.routes.review)
            )}
          />
        </Route>
        <Route
          name="New Survey Outcome"
          path="review/:fiscalYear/:id/survey-outcome/:amsSubmissionId/:amsFormId"
        >
          <Route
            path="child-files"
            getDisplayName={param => `Eligibility File Submission Results`}
            component={withTracker(
              requireAuth(ChildFileOutcomePaths, acl.routes.review)
            )}
          />
        </Route>
        <Route
          name="Reviews"
          path="review/fy19/:id/performance-rating-summary"
          getDisplayName={param => `Performance Rating Summary`}
          component={withTracker(requireAuth(ReviewsOCISummaryPage))}
        />
        <Route
          name="Reviews"
          path="review/:fiscalYear/:id/performance-rating-summary"
          getDisplayName={param => `Performance Rating Summary`}
          component={withTracker(requireAuth(ReviewsOCISummaryPage))}
        />
        <Route
          name="Reviews"
          path="review/:fiscalYear/:id/center-classroom-fa2-exploration-summary"
          getDisplayName={param => `Center and Classroom Exploration Summary`}
          component={withTracker(
            requireAuth(ReviewsFA2CenterAndExplorationSummary)
          )}
        />
        <Route
          name="Reviews"
          path="review/:fiscalYear/:id/manage-center-classroom"
          getDisplayName={param => `Manage Centers/Classrooms`}
          component={withTracker(requireAuth(ReviewsCenterClassroomManagement))}
        />
        <Route
          name="Reviews"
          path="review/:id/findings"
          getDisplayName={param => `Findings Analysis`}
          component={withTracker(requireAuth(ReviewsFindingsPage))}
        />
        <Route
          name="Reviews"
          path="review/fy19/:id/findings"
          getDisplayName={param => `Findings Analysis`}
          component={withTracker(requireAuth(ReviewsFindingsPage))}
        />
        <Route
          name="Reviews"
          path="review/:fiscalYear/:id/findings"
          getDisplayName={param => `Findings Analysis`}
          component={withTracker(requireAuth(ReviewsFindingsPage))}
        />
        <Route
          name="Reviews"
          path="review/:fiscalYear/:id/findings-analysis"
          getDisplayName={param => `Findings Analysis`}
          component={withTracker(requireAuth(FindingsPage))}
        />
        <Route
          name="Reviews"
          path="review/:fiscalYear/:id/management_systems_analysis_summary"
          getDisplayName={param => `Management Systems Analysis Summary`}
          component={withTracker(
            requireAuth(ReviewManagementAnalysisReportPage)
          )}
        />
        <Route
          name="Reviews"
          path="review/:fiscalYear/:id/pre_review_summary"
          getDisplayName={param => `Pre-Review Summary`}
          component={withTracker(requireAuth(PreReviewSummaryReportPage))}
        />
        <Route
          name="Reviews"
          path="review/:id/follow-up-recommendations-comments"
          component={withTracker(
            requireAuth(ReviewsFollowUpRecommendationsCommentsPage)
          )}
        />
        <Route
          name="Reviews"
          path="review/fy19/:id/follow-up-recommendations-comments"
          component={withTracker(
            requireAuth(ReviewsFollowUpRecommendationsCommentsPage)
          )}
        />
        <Route
          name="Reviews"
          path="review/:fiscalYear/:id/follow-up-recommendations-comments"
          component={withTracker(
            requireAuth(ReviewsFollowUpRecommendationsCommentsPage)
          )}
        />
        <Route
          name="Reviews"
          path="review/:id/follow-up-recommendations"
          component={withTracker(
            requireAuth(ReviewsFollowUpRecommendationsPage)
          )}
        />
        <Route
          name="Reviews"
          path="review/fy19/:id/follow-up-recommendations"
          component={withTracker(
            requireAuth(ReviewsFollowUpRecommendationsPage)
          )}
        />
        <Route
          name="Reviews"
          path="review/:fiscalYear/:id/follow-up-recommendations"
          component={withTracker(
            requireAuth(ReviewsFollowUpRecommendationsPage)
          )}
        />
        <Route
          name="Reviews"
          path="review/:id/follow-up-recommendations/:citationId"
          component={withTracker(
            requireAuth(ReviewsFollowUpRecommendationsPage)
          )}
        />
        <Route
          name="Reviews"
          path="review/fy19/:id/follow-up-recommendations/:citationId"
          component={withTracker(
            requireAuth(ReviewsFollowUpRecommendationsPage)
          )}
        />
        <Route
          name="Reviews"
          path="review/:fiscalYear/:id/follow-up-recommendations/:citationId"
          component={withTracker(
            requireAuth(ReviewsFollowUpRecommendationsPage)
          )}
        />
        <Route
          name="Reviews"
          path="review/fy19/:id/follow-ups"
          getDisplayName={param => `Follow-Up Reviews`}
          component={withTracker(requireAuth(ReviewFollowUps))}
        />
        <Route
          name="Reviews"
          path="review/:fiscalYear/:id/follow-ups"
          getDisplayName={param => `Follow-up Reviews`}
          component={withTracker(requireAuth(ReviewFollowUps))}
        />
        <Route name="Task" path="task">
          <IndexRoute
            // getDisplayName={param => `CLASS Survey`}
            component={withTracker(requireAuth(TaskPage, acl.routes.task))}
          />
          <Route
            path=":fiscalYear/:reviewId/survey/class"
            getDisplayName={param => `CLASS Survey`}
            component={withTracker(requireAuth(CLASSSurveyPage))}
          />
          <Route
            path=":reviewId/survey/class-replacement"
            getDisplayName={param => `CLASS Replacement`}
            component={withTracker(requireAuth(CLASSReplacementForm))}
          />
          <Route
            path=":reviewId/survey/follow-up"
            getDisplayName={param => `Follow-up Survey`}
            component={withTracker(requireAuth(FollowUpSurveyPaths))}
          />
          <Route
            path=":reviewId/survey/fa1"
            getDisplayName={param => `FA1 Survey`}
            component={withTracker(requireAuth(FA1Survey))}
          />
          <Route
            path=":reviewId/survey/fa2"
            getDisplayName={param => `FA2 Survey`}
            component={withTracker(requireAuth(FA2Survey))}
          />
          <Route
            path=":reviewId/survey/special"
            getDisplayName={param => `Special Survey`}
            component={withTracker(requireAuth(FA2Survey))}
          />
          <Route
            path=":reviewId/survey/presite"
            getDisplayName={param => `Pre-Review`}
            component={withTracker(requireAuth(PresiteSurvey))}
          />
          <Route
            path=":reviewId/survey/ran"
            getDisplayName={param => `RAN`}
            component={withTracker(requireAuth(RANSurveyPaths))}
          />
          <Route
            path=":reviewId/survey/aian-def"
            getDisplayName={param => `AIAN-DEF`}
            component={withTracker(requireAuth(FA2Survey))}
          />
          <Route
            path=":reviewId/survey/ersea"
            getDisplayName={param => `ERSEA`}
            component={withTracker(requireAuth(FA2Survey))}
          />

          <Route
            path=":reviewId/findings/:conversationId"
            component={withTracker(
              requireAuth(SurveyReportTrackerFindingsPage)
            )}
            getDisplayName={param => `Findings`}
          />
          <Route
            path=":reviewId/survey/fa2cr"
            getDisplayName={param => `FA2CR Survey`}
            component={withTracker(requireAuth(FA2Survey))}
          />
          <Route
            path=":reviewId/survey/fa1fr"
            getDisplayName={param => `FA1FR Survey`}
            component={withTracker(requireAuth(FA1Survey))}
          />
          <Route
            path=":reviewId/findings"
            component={withTracker(
              requireAuth(SurveyReportTrackerFindingsPage)
            )}
            getDisplayName={param => `Findings`}
          />
          <Route
            path="findings"
            component={withTracker(
              requireAuth(SurveyReportTrackerFindingsPage)
            )}
            getDisplayName={param => `All Findings`}
          />
          <Route
            path=":reviewId/legacyFindings"
            component={withTracker(requireAuth(ReportTrackerFindingsPage))}
            getDisplayName={param => `All Findings`}
          />
          <Route
            path="allFindings"
            component={withTracker(
              requireAuth(SurveyAllReportTrackerFindingsPage)
            )}
            getDisplayName={param => `All Findings`}
          />
          <Route
            path=":reviewId/survey/eas/fa1-r"
            getDisplayName={param => `FA1-R Survey`}
            component={withTracker(requireAuth(EASSurveyForm))}
          />
        </Route>
        <Route
          path=":reviewId/survey/eas/followup"
          getDisplayName={param => `Follow-up Survey`}
          component={withTracker(requireAuth(EASSurveyForm))}
        />
      </Route>
    </Router>
  </Provider>,
  document.getElementById('root')
);
