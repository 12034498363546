import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { includes, filter, isEmpty, find, isArray } from 'lodash';
import {
  Form,
  Button,
  Header,
  Dropdown,
  Modal,
  Input,
  Grid,
} from 'semantic-ui-react';

import AmsModal from '../../../utils/AmsModal';
import AmsDateFormatters from '../../../utils/AmsDateFormatters';
import AmsLookupDropdown from '../../../utils/AmsLookupDropdown';
import AgencyLookup from '../../../utils/AgencyLookup';
import AmsHelpText from '../../../utils/AmsHelpText';
import AmsFormLabel from '../../../utils/AmsFormLabel';
import GranteeListDropdown from '../../../utils/GranteeListDropdown';
import DatePicker from 'react-datepicker';
import AmsCitationDropdown from '../../../utils/AmsCitationDropdown';
import { fetchFOMUsers } from '../../../actions/userActions';
import { fetchAssigneesFilter } from '../../../actions/reportActions';
import queryString from 'query-string';

const SummaryFilters = props => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [modalFilters, setModalFilters] = useState([]);
  const [filters, setFilters] = useState({});

  const [region, setRegion] = useState('');
  const [agencyId, setAgencyId] = useState('');
  const [selectedGrantees, setSelectedGrantees] = useState([]);
  const [status, setStatus] = useState('');
  const [outcome, setOutcome] = useState('');
  const [fiscalYear, setFiscalYear] = useState(null);

  const [reviewTypes, setReviewTypes] = useState([]);
  const [reviewIdList, setReviewIdList] = useState([]);
  const [reviewId, setReviewId] = useState('');
  const [fomFilter, setFomFilter] = useState('');
  const [reportAssigneeList, setReportAssigneeList] = useState([]);
  const [year, setYear] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [errors, setErrors] = useState({});
  const [taskStatus, setTaskStatus] = useState([]);
  const [taskDefKey, setTaskDefKey] = useState([]);
  const [toDate, setToDate] = useState(null);

  const [selectedReviewStatus, setSelectedReviewStatus] = useState([]);
  const [selectedReviewOutcome, setSelectedReviewOutcome] = useState([]);
  const [findings, setFindings] = useState('');
  const [selectedFindingTypes, setSelectedFindingTypes] = useState([]);
  const [selectedCitation, setSelectedCitation] = useState([]);
  const [disallowance, setDisallowance] = useState('');

  useEffect(() => {
    setModalFilters(props.defaultFilters);
    setSelectedFilters(props.defaultFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrors(props.errors);
    setYear(props.year);
    setReportAssigneeList(props.reportAssigneeList);
    if (errors) setFilters({ ...filters, errors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors, errors, props.year, props.reportAssigneeList]);

  useEffect(() => {
    let fil = {};
    if (region) fil.region = region;
    if (agencyId) fil.agencyId = agencyId;
    if (selectedGrantees) fil.selectedGrantees = selectedGrantees;
    if (status) fil.status = status;
    if (outcome) fil.outcome = outcome;
    if (fiscalYear) fil.fiscalYear = fiscalYear;
    if (reviewTypes) fil.reviewTypes = reviewTypes;
    if (reviewIdList) fil.reviewIdList = reviewIdList;
    if (reviewId) fil.reviewId = reviewId;
    if (fomFilter) fil.fomFilter = fomFilter;
    if (reportAssigneeList) fil.reportAssigneeList = reportAssigneeList;
    if (year) fil.year = year;
    if (fromDate) fil.fromDate = fromDate;
    if (taskStatus) fil.taskStatus = taskStatus;
    if (taskDefKey) fil.taskDefKey = taskDefKey;
    if (toDate) fil.toDate = toDate;
    if (selectedReviewStatus) fil.selectedReviewStatus = selectedReviewStatus;
    if (selectedReviewOutcome)
      fil.selectedReviewOutcome = selectedReviewOutcome;
    if (findings !== '') fil.findings = findings;
    if (selectedFindingTypes) fil.selectedFindingTypes = selectedFindingTypes;
    if (selectedCitation) fil.selectedCitation = selectedCitation;
    if (disallowance !== '') fil.disallowance = disallowance;
    setFilters(fil);
  }, [
    region,
    agencyId,
    selectedGrantees,
    status,
    outcome,
    fiscalYear,
    reviewTypes,
    reviewIdList,
    reviewId,
    fomFilter,
    reportAssigneeList,
    year,
    fromDate,
    taskStatus,
    taskDefKey,
    toDate,
    selectedReviewStatus,
    selectedReviewOutcome,
    findings,
    selectedFindingTypes,
    selectedCitation,
    disallowance,
  ]);

  useEffect(() => {
    if (!filters.fiscalYear && props.fiscalYear) {
      const preselect = isArray(props.fiscalYear)
        ? props.fiscalYear.map(i => Number(i))
        : Number(props.fiscalYear);
      setFiscalYear(preselect);
    }
  }, [props.fiscalYear]);

  useEffect(() => {
    if (!filters.status && props.status) {
      setStatus(props.status);
    }
  }, [props.status]);

  useEffect(() => {
    const { search } = props.location;
    if (search) {
      let filterQueryString =
        search &&
        queryString.parse(search, {
          arrayFormat: 'bracket',
          parseBooleans: true,
        });

      if (!isEmpty(filterQueryString['fiscalYear']))
        filterQueryString['fiscalYear'] = isArray(
          filterQueryString['fiscalYear']
        )
          ? filterQueryString['fiscalYear'].map(i => Number(i))
          : Number(filterQueryString['fiscalYear']);
      if (!isEmpty(filterQueryString['year']))
        filterQueryString['year'] = filterQueryString['year'].map(i =>
          Number(i)
        );
      if (!isEmpty(filterQueryString['toDate']))
        filterQueryString['toDate'] = AmsDateFormatters.getMoment(
          filterQueryString['toDate']
        ).endOf('day');

      if (!isEmpty(filterQueryString['fromDate']))
        filterQueryString['fromDate'] = AmsDateFormatters.getMoment(
          filterQueryString['fromDate']
        ).startOf('day');
      if (!isEmpty(filterQueryString['agencyId']))
        filterQueryString['agencyId'] = Number(filterQueryString['agencyId']);

      setFilters({ ...filterQueryString });
      if (!isEmpty(filterQueryString)) {
        let filters = !isEmpty(selectedFilters)
          ? selectedFilters
          : props.defaultFilters;
        let newFilter = {};
        for (const filterKey in filterQueryString) {
          newFilter = reloadFilters(filterKey, filterQueryString);
          if (
            newFilter &&
            (find(
              filters,
              filter => filter.key === filterKey && filter.value === false
            ) ||
              !find(filters, filter => filter.key === filterKey))
          ) {
            filters.push(newFilter);
          }
        }
        setModalFilters(filters);
        setSelectedFilters(filters);
      }

      props.data.handleFilterSubmit({ ...filterQueryString });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search]);

  const reloadFilters = (key, queryString) => {
    switch (key) {
      case 'fiscalYear':
        if (isArray(queryString['fiscalYear']))
          setFiscalYear(queryString['fiscalYear'].map(i => Number(i)));
        else setFiscalYear(Number(queryString['fiscalYear']));
        return { key: 'fiscalYear', value: true };
      // break;
      case 'year':
        setYear(queryString['year'].map(i => Number(i)));
        // return { key: 'fiscalYear', value: true };
        break;
      case 'reviewId':
        setReviewId(queryString['reviewId']);
        break;
      case 'reviewIdList':
        setReviewIdList(queryString['reviewIdList']);
        break;
      case 'reviewTypes':
        setReviewTypes(queryString['reviewTypes']);
        return { key: 'reviewTypes', value: true };
      case 'region':
        setRegion(queryString['region']);
        return { key: 'region', value: true };
      case 'agencyId':
        setAgencyId(queryString['agencyId']);
        return { key: 'agencyId', value: true };
      case 'selectedGrantees':
        setSelectedGrantees(queryString['selectedGrantees']);
        return { key: 'selectedGrantees', value: true };
      case 'status':
        setStatus(queryString['status']);
        return { key: 'status', value: true };
      case 'selectedReviewStatus':
        setSelectedReviewStatus(queryString['selectedReviewStatus']);
        return { key: 'selectedReviewStatus', value: true };
      case 'outcome':
        setOutcome(queryString['outcome']);
        return { key: 'outcome', value: true };
      case 'fomFilter':
        setFomFilter(queryString['fomFilter']);
        return { key: 'fomFilter', value: true };
      case 'findings':
        setFindings(queryString['findings']);
        return { key: 'findings', value: true };
      case 'toDate':
        setToDate(
          AmsDateFormatters.getMoment(queryString['toDate']).endOf('day')
        );
        return { key: 'toDate', value: true };
      case 'fromDate':
        setFromDate(
          AmsDateFormatters.getMoment(queryString['fromDate']).startOf('day')
        );
        return { key: 'fromDate', value: true };
      case 'selectedCitation':
        setSelectedCitation(queryString['selectedCitation']);
        return { key: 'selectedCitation', value: true };
      case 'selectedFindingTypes':
        setSelectedFindingTypes(queryString['selectedFindingTypes']);
        return { key: 'selectedFindingTypes', value: true };
      case 'reportAssigneeList':
        setReportAssigneeList(queryString['reportAssigneeList']);
        return { key: 'reportAssigneeList', value: true };
      case 'selectedReviewOutcome':
        setSelectedReviewOutcome(queryString['selectedReviewOutcome']);
        return { key: 'selectedReviewOutcome', value: true };
      case 'taskStatus':
        setTaskStatus(queryString['taskStatus']);
        return { key: 'taskStatus', value: true };
      case 'taskDefKey':
        setTaskDefKey(queryString['taskDefKey']);
        return { key: 'taskDefKey', value: true };
      case 'disallowance':
        setDisallowance(queryString['disallowance']);
        return { key: 'disallowance', value: true };
      default:
        break;
    }
  };

  const handleSelectedAgency = value => {
    setAgencyId(value);
  };

  const handleSelectedGranteesChange = value => {
    setSelectedGrantees([value]);
  };

  const handleStartChange = start => {
    if (!start) {
      setFromDate(null);
      setToDate(null);
      return;
    }
    setFromDate(AmsDateFormatters.getMoment(start).startOf('day'));
  };

  const handleEndChange = end => {
    if (!end) {
      setFromDate(null);
      setToDate(null);
      return;
    }
    setToDate(AmsDateFormatters.getMoment(end).endOf('day'));
  };

  const handleReviewTypeChange = (e, { value }) => {
    setReviewTypes(value);
  };

  const handleSearchInputhChange = (e, { value }) => {
    value !== '' ? setReviewIdList([value]) : setReviewIdList([]);
  };

  const handleSearchInputChange = (e, { value }) => {
    setReviewId(value);
  };

  const handleCitationValueChange = value => {
    setSelectedCitation(value);
  };

  const handleSelectedAssigneeChange = (ev, { value }) => {
    value !== '' ? setReportAssigneeList([value]) : setReportAssigneeList([]);
  };

  const handleManagerValueChange = (e, { value }) => {
    setFomFilter(value);
  };

  const handleFYChange = (e, { value }) => {
    let newYear = [];
    if (value.length) {
      newYear = [value[value.length - 1]];
    }
    setYear(newYear);
  };

  const filtersMapping = key => {
    let selectedAssigneeList = (props.fetchedAssigneesFilterList || []).map(
      user => {
        return {
          key: user.oid,
          value: user.oid,
          text: user.name,
        };
      }
    );

    if (props.page === 'Report') {
      selectedAssigneeList.unshift({
        key: 'any',
        value: 'any',
        text: 'All Assignees',
      });
    }

    const fomUsersList =
      props.fomUsers.map((fom, index) => ({
        key: index,
        value: fom.oid,
        text: fom.fullName,
      })) || [];
    switch (key) {
      case 'region':
        return (
          <Grid.Column width={4}>
            <Form.Field>
              <AmsFormLabel
                name="Region"
                helpText={false}
                fieldLabel="filterRegion"
              >
                <AmsLookupDropdown
                  aria-labelledby="filterRegion"
                  searchInput={{
                    id: 'filterRegion',
                  }}
                  placeholder="Select Region"
                  value={region}
                  search
                  selection
                  category={'regions'}
                  onChange={(e, { value }) => setRegion(value)}
                />
              </AmsFormLabel>
            </Form.Field>
          </Grid.Column>
        );
      case 'agencyId':
        return (
          <Grid.Column width={4}>
            <Form.Field>
              <AmsFormLabel
                name="Agency Name/ID"
                category="helpText"
                fieldLabel="filterAgencyID"
              >
                <AgencyLookup
                  placeholder="Enter Agency Name/ID"
                  value={agencyId}
                  aria-labelledby="filterAgencyID"
                  searchInput={{
                    id: 'filterAgencyID',
                    title: 'Enter Agency Name/ID',
                  }}
                  search
                  selection
                  onValueSelected={handleSelectedAgency}
                />
              </AmsFormLabel>
            </Form.Field>
          </Grid.Column>
        );
      case 'selectedGrantees':
        return (
          <Grid.Column width={4}>
            <Form.Field
              dropdownLabel="Grantee"
              placeholder="Enter Grantee Name/ID"
              status={['relinquished', 'active', 'inactive', 'terminated']}
              control={GranteeListDropdown}
              value={(selectedGrantees && selectedGrantees[0]) || ''}
              selection
              search
              onValueSelected={handleSelectedGranteesChange}
              onError={error => console.log(error)}
            />
          </Grid.Column>
        );
      case 'status':
        return (
          <Grid.Column width={4}>
            <Form.Field>
              <AmsFormLabel
                name="Status"
                helpText={false}
                fieldLabel="filterStatus"
              >
                <AmsLookupDropdown
                  placeholder={
                    props.page === 'Review'
                      ? 'Select Review Status'
                      : 'Select Grantee Status'
                  }
                  value={status}
                  search
                  selection
                  clearable
                  multiple={props.page === 'Review' ? false : true}
                  aria-labelledby="filterStatus"
                  searchInput={{
                    id: 'filterStatus',
                    title:
                      props.page === 'Review'
                        ? 'Select Review Status'
                        : 'Select Grantee Status',
                  }}
                  category={'granteeStatus'}
                  onChange={(e, { value }) => setStatus(value)}
                />
              </AmsFormLabel>
            </Form.Field>
          </Grid.Column>
        );
      case 'outcome':
        return (
          <Grid.Column width={4}>
            <Form.Field>
              <AmsFormLabel
                name="Last Review Outcome"
                helpText={false}
                fieldLabel="filterLastReviewOutcome"
              >
                <AmsLookupDropdown
                  placeholder="Select Last Review Outcome"
                  value={outcome}
                  search
                  aria-labelledby="filterLastReviewOutcome"
                  searchInput={{ id: 'filterLastReviewOutcome' }}
                  selection
                  control={AmsLookupDropdown}
                  category={'reviewOutcome'}
                  onChange={(e, { value }) => setOutcome(value)}
                />
              </AmsFormLabel>
            </Form.Field>
          </Grid.Column>
        );
      case 'fiscalYear': {
        switch (props.page) {
          case 'Review':
            return (
              <Grid.Column width={4}>
                <Form.Field>
                  <AmsFormLabel
                    name="Fiscal Year"
                    helpText={false}
                    fieldLabel="filterFiscalYear"
                  >
                    <AmsLookupDropdown
                      placeholder="Select Fiscal Year"
                      value={fiscalYear}
                      fluid
                      search
                      selection
                      multiple
                      aria-labelledby="filterFiscalYear"
                      searchInput={{
                        id: 'filterFiscalYear',
                        title: 'Select Fiscal Year',
                      }}
                      category={'fiscalYear'}
                      onChange={(e, { value }) => {
                        setFiscalYear(value);
                      }}
                    />
                  </AmsFormLabel>
                </Form.Field>
              </Grid.Column>
            );
          case 'Grantee':
            return (
              <Grid.Column width={4}>
                <Form.Field>
                  <AmsFormLabel
                    name="Fiscal Year"
                    helpText={false}
                    fieldLabel="filterFiscalYear"
                  >
                    <AmsLookupDropdown
                      placeholder="Select Fiscal Year"
                      value={fiscalYear}
                      search
                      aria-labelledby="filterFiscalYear"
                      searchInput={{
                        id: 'filterFiscalYear',
                        title: 'Select Fiscal Year',
                      }}
                      selection
                      category={'fiscalYear'}
                      onChange={(e, { value }) => {
                        setFiscalYear(value);
                      }}
                    />
                  </AmsFormLabel>
                </Form.Field>
              </Grid.Column>
            );
          case 'Report':
            return (
              <Grid.Column width={4}>
                <Form.Field>
                  <AmsFormLabel
                    name="Fiscal Year"
                    helpText={false}
                    fieldLabel="filterFiscalYear"
                  >
                    <AmsLookupDropdown
                      name="Fiscal Year"
                      placeholder="Select Fiscal Year"
                      value={year}
                      fluid
                      search
                      selection
                      multiple
                      control={AmsLookupDropdown}
                      category={'fiscalYear'}
                      searchInput={{
                        id: 'filterFiscalYear',
                        title: 'Select Fiscal Year',
                      }}
                      aria-labelledby="filterFiscalYear"
                      onChange={handleFYChange}
                    />
                  </AmsFormLabel>
                </Form.Field>
              </Grid.Column>
            );
          default:
            return '';
        }
      }
      case 'reviewTypes':
        return (
          <Grid.Column width={4}>
            <Form.Field>
              <AmsFormLabel
                name="Review Type"
                helpText={false}
                fieldLabel="filterReviewType"
              >
                <AmsLookupDropdown
                  placeholder="Select Review Type"
                  value={reviewTypes}
                  onChange={handleReviewTypeChange}
                  fluid
                  multiple
                  search
                  selection
                  category={'reviewTypes'}
                  searchInput={{
                    id: 'filterReviewType',
                    title: 'Select Review Type',
                  }}
                  aria-labelledby="filterReviewType"
                />
              </AmsFormLabel>
            </Form.Field>
          </Grid.Column>
        );
      case 'reviewId':
        return (
          <Grid.Column width={4} key="reviewId">
            <Form.Field
              label={{
                children: (
                  <AmsHelpText
                    category="helpText"
                    fieldLabel="filterReviewID"
                    label="Review ID"
                  />
                ),
                htmlFor: 'filterReviewID',
              }}
              control={Input}
              placeholder="Enter Review ID"
              value={props.page === 'Report' ? reviewIdList : reviewId}
              maxLength="10"
              onChange={
                props.page === 'Report'
                  ? handleSearchInputhChange
                  : handleSearchInputChange
              }
              fluid
              id={'filterReviewID'}
              aria-labelledby={'filterReviewID'}
            ></Form.Field>
          </Grid.Column>
        );
      case 'selectedReviewOutcome':
        return (
          <Grid.Column width={4}>
            <Form.Field>
              <AmsFormLabel
                name="Outcome"
                helpText={false}
                fieldLabel="filterOutcome"
              >
                <AmsLookupDropdown
                  placeholder="Select Outcome"
                  value={selectedReviewOutcome}
                  onChange={(e, { value }) => {
                    setSelectedReviewOutcome(value);
                  }}
                  fluid
                  multiple
                  search
                  selection
                  category={
                    props.page === 'Review' ? 'reviewOutcome' : 'outcomeTypes'
                  }
                  searchInput={{
                    id: 'filterOutcome',
                    title: 'Select Outcome',
                  }}
                  aria-labelledby="filterOutcome"
                />
              </AmsFormLabel>
            </Form.Field>
          </Grid.Column>
        );
      case 'reportAssigneeList':
        return (
          <Grid.Column width={4}>
            <Form.Field>
              <AmsFormLabel
                name="Assignee"
                helpText={false}
                fieldLabel="filterAssignee"
              >
                <Dropdown
                  fluid
                  selection
                  search
                  clearable
                  searchInput={{
                    id: 'filterAssignee',
                    title: 'Select Assignee',
                  }}
                  placeholder={'Select Assignee'}
                  value={
                    reportAssigneeList && reportAssigneeList[0]
                      ? reportAssigneeList[0]
                      : ''
                  }
                  options={selectedAssigneeList}
                  loading={!props.fetchedAssigneesFilterList}
                  onChange={handleSelectedAssigneeChange}
                  aria-labelledby={'filterAssignee'}
                  selectOnBlur={false}
                  openOnFocus={false}
                />
              </AmsFormLabel>
            </Form.Field>
          </Grid.Column>
        );
      case 'fomFilter':
        return (
          <Grid.Column width={4}>
            <Form.Field>
              <AmsFormLabel
                name="Manager"
                helpText={false}
                fieldLabel="filterManager"
              >
                <Dropdown
                  name="fomFilter"
                  value={fomFilter}
                  options={fomUsersList}
                  onChange={handleManagerValueChange}
                  placeholder="Select Manager"
                  selection
                  search
                  searchInput={{
                    id: 'filterManager',
                    title: 'Select Manager',
                  }}
                  aria-labelledby="filterManager"
                  selectOnBlur={false}
                  openOnFocus={false}
                />
              </AmsFormLabel>
            </Form.Field>
          </Grid.Column>
        );
      case 'fromDate':
        return (
          <Grid.Column width={4} key="fromDate">
            <Form.Field error={errors && !!errors.fromDate}>
              <label htmlFor="filterFromDate">From Date</label>
              <DatePicker
                fluid
                selectsStart
                id="filterFromDate"
                dateFormat="MM/DD/YYYY"
                placeholderText="Select From Date"
                selected={fromDate}
                fromDate={fromDate}
                value={fromDate ? fromDate : ''}
                onChange={handleStartChange}
              />
              {errors && errors.fromDate && (
                <span className="help-block">{errors.fromDate}</span>
              )}
            </Form.Field>
          </Grid.Column>
        );
      case 'toDate':
        return (
          <Grid.Column width={4} key="toDate">
            <Form.Field error={errors && !!errors.toDate}>
              <label htmlFor="filterToDate">To Date</label>
              <DatePicker
                fluid
                selectsEnd
                id="filterToDate"
                dateFormat="MM/DD/YYYY"
                placeholderText="Select To Date"
                fromDate={fromDate}
                minDate={fromDate}
                selected={toDate}
                toDate={toDate}
                onChange={handleEndChange}
              />
              {errors && errors.toDate && (
                <span className="help-block">{errors.toDate}</span>
              )}
            </Form.Field>
          </Grid.Column>
        );
      case 'taskStatus':
        return (
          <Grid.Column width={4}>
            <Form.Field>
              <AmsFormLabel
                name="Task Status"
                helpText={false}
                fieldLabel="filterTaskStatus"
              >
                <AmsLookupDropdown
                  placeholder="Select Task Status"
                  value={taskStatus}
                  multiple
                  aria-labelledby="filterTaskStatus"
                  searchInput={{
                    id: 'filterTaskStatus',
                  }}
                  search
                  selection
                  category={'taskStatus'}
                  onChange={(e, { value }) => {
                    setTaskStatus(value);
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
          </Grid.Column>
        );
      case 'taskDefKey':
        return (
          <Grid.Column width={4}>
            <Form.Field>
              <AmsFormLabel
                name="Task Type"
                helpText={false}
                fieldLabel="filterTaskType"
              >
                <AmsLookupDropdown
                  placeholder="Select Task Type"
                  value={taskDefKey}
                  multiple
                  aria-labelledby="filterTaskType"
                  searchInput={{
                    id: 'filterTaskType',
                  }}
                  search
                  selection
                  category={'taskTypes'}
                  onChange={(e, { value }) => {
                    setTaskDefKey(value);
                  }}
                />
              </AmsFormLabel>
            </Form.Field>
          </Grid.Column>
        );
      case 'selectedReviewStatus':
        return (
          <Grid.Column width={4}>
            <Form.Field>
              <AmsFormLabel
                name="Review Status"
                helpText={false}
                fieldLabel="filterReviewStatus"
              >
                <AmsLookupDropdown
                  aria-labelledby="filterReviewStatus"
                  placeholder="Select Review Status"
                  value={selectedReviewStatus}
                  onChange={(e, { value }) => {
                    setSelectedReviewStatus(value);
                  }}
                  fluid
                  searchInput={{
                    id: 'filterReviewStatus',
                    title: 'Select Review Status',
                  }}
                  multiple
                  search
                  selection
                  // clearable
                  category={'reviewStatus'}
                />
              </AmsFormLabel>
            </Form.Field>
          </Grid.Column>
        );
      case 'findings':
        return (
          <Grid.Column width={4}>
            <Form.Field>
              <AmsFormLabel
                name="Findings"
                fieldLabel="filterFindings"
                helpText={false}
              >
                <AmsLookupDropdown
                  searchInput={{
                    id: 'filterFindings',
                    title: 'Select Findings',
                  }}
                  placeholder="Select Findings"
                  value={findings}
                  onChange={(e, { value }) => {
                    setFindings(value);
                  }}
                  fluid
                  search
                  selection
                  aria-labelledby="filterFindings"
                  // clearable
                  category={'findings'}
                />
              </AmsFormLabel>
            </Form.Field>
          </Grid.Column>
        );
      case 'selectedFindingTypes':
        return (
          <Grid.Column width={4}>
            <Form.Field>
              <AmsFormLabel
                helpText={false}
                fieldLabel="filterFindingTypes"
                name="Finding Types"
              >
                <AmsLookupDropdown
                  required
                  placeholder="Select Finding Types"
                  value={selectedFindingTypes}
                  onChange={(e, { value }) => {
                    setSelectedFindingTypes(value);
                  }}
                  searchInput={{
                    id: 'filterFindingTypes',
                    title: 'Select Finding Types',
                  }}
                  aria-labelledby="filterFindingTypes"
                  fluid
                  multiple
                  search
                  selection
                  // clearable
                  category={'findingType'}
                />
              </AmsFormLabel>
            </Form.Field>
          </Grid.Column>
        );
      case 'selectedCitation':
        return (
          <Grid.Column width={4}>
            <Form.Field>
              <AmsCitationDropdown
                value={selectedCitation}
                multiple
                selection
                search
                onValueSelected={handleCitationValueChange}
                onError={error => console.log(error)}
              />
            </Form.Field>
          </Grid.Column>
        );
      case 'disallowance':
        return (
          <Grid.Column width={4}>
            <Form.Field>
              <AmsFormLabel
                name="Disallowance"
                fieldLabel="filterDisallowance"
                helpText={false}
              >
                <AmsLookupDropdown
                  searchInput={{
                    id: 'filterDisallowance',
                    title: 'Select Disallowance',
                  }}
                  placeholder="Select Disallowance"
                  value={disallowance}
                  onChange={(e, { value }) => {
                    setDisallowance(value);
                  }}
                  fluid
                  search
                  selection
                  aria-labelledby="filterDisallowance"
                  // clearable
                  category={'findings'}
                />
              </AmsFormLabel>
            </Form.Field>
          </Grid.Column>
        );
      default:
        return '';
    }
  };

  const renderFilters = () => {
    return (
      <Form className="filter-form">
        <Grid stackable>
          {selectedFilters &&
            // eslint-disable-next-line array-callback-return
            selectedFilters.map(fil => {
              if (fil.value === true) return filtersMapping(fil.key);
            })}
          <Grid.Column width={4}>
            <Form.Group>
              <Form.Field>
                <Form.Button
                  className="filter-button"
                  primary
                  onClick={() => {
                    props.data.handleFilterSubmit(filters);
                    if (!isEmpty(filters)) {
                      let cleanFilters = filters;
                      if (isEmpty(cleanFilters['errors']))
                        delete cleanFilters['errors'];
                      const qs = queryString.stringify(cleanFilters, {
                        arrayFormat: 'bracket',
                        parseBooleans: true,
                        skipNull: true,
                      });
                      props.router.replace(`${props.location.pathname}?${qs}`);
                    }
                  }}
                  content="Filter"
                />
              </Form.Field>
              <Form.Field>
                <Form.Button
                  className="filter-button"
                  basic
                  onClick={() => {
                    let fiscalYear = null;
                    if (new Date().getMonth() < 10) {
                      fiscalYear = new Date().getYear() + 1900;
                    } else {
                      fiscalYear = new Date().getYear() + 1901;
                    }

                    if (props.page === 'Review') {
                      setFiscalYear([fiscalYear]);
                    } else {
                      setFiscalYear(null);
                    }

                    if (props.page === 'Grantee') {
                      setStatus(['Active']);
                    } else {
                      setStatus('');
                    }

                    setRegion('');
                    setAgencyId('');
                    setSelectedGrantees([]);

                    setOutcome('');

                    setReviewTypes([]);
                    setReviewIdList([]);
                    setReviewId('');
                    setFomFilter('');
                    setReportAssigneeList([]);
                    setYear([]);
                    setFromDate(null);
                    setTaskStatus([]);
                    setTaskDefKey([]);
                    setToDate(null);
                    setSelectedReviewStatus([]);
                    setSelectedReviewOutcome([]);
                    setFindings('');
                    setSelectedFindingTypes([]);
                    setSelectedCitation([]);
                    setDisallowance('');
                    const qs = queryString.stringify(
                      {},
                      {
                        arrayFormat: 'bracket',
                      }
                    );

                    props.router.replace(`${props.location.pathname}${qs}`);
                    props.data.handleFilterReset(filters);
                  }}
                  content="Reset"
                />
              </Form.Field>
              <Form.Field>
                <Form.Button
                  className="filter-button"
                  primary
                  content={'Advanced Filter'}
                  onClick={() => {
                    setShowFilterModal(true);
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </Grid>
        <div className="clearfix" />
      </Form>
    );
  };

  const closeModal = () => {
    setShowFilterModal(false);
    setModalFilters(selectedFilters);
  };

  const filterOptions = () => {
    const options = [
      {
        key: 'status',
        text: 'Status',
        value: 'status',
        page: ['Grantee'],
      },
      {
        key: 'outcome',
        text: 'Last Review Outcome',
        value: 'outcome',
        page: ['Grantee'],
      },
      {
        key: 'fiscalYear',
        text: 'Fiscal Year',
        value: 'fiscalYear',
        page: ['Grantee', 'Report', 'Review'],
      },
      {
        key: 'region',
        text: 'Region',
        value: 'region',
        page: ['Grantee', 'Report', 'Review'],
      },
      {
        key: 'agencyId',
        text: 'Agency Name/ID',
        value: 'agencyId',
        page: ['Grantee', 'Review'],
      },
      {
        key: 'selectedGrantees',
        text: 'Grantee',
        value: 'selectedGrantees',
        page: ['Grantee', 'Report', 'Task', 'Review'],
      },
      {
        key: 'reviewTypes',
        text: 'Review Type',
        value: 'reviewTypes',
        page: ['Report', 'Review'],
      },
      {
        key: 'reviewId',
        text: 'Review ID',
        value: 'reviewId',
        page: ['Report', 'Task', 'Review'],
      },
      {
        key: 'selectedReviewOutcome',
        text: 'Outcome',
        value: 'selectedReviewOutcome',
        page: ['Report', 'Review'],
      },
      {
        key: 'reportAssigneeList',
        text: 'Assignee',
        value: 'reportAssigneeList',
        page: ['Report'],
      },
      {
        key: 'fomFilter',
        text: 'Manager',
        value: 'fomFilter',
        page: ['Report', 'Review'],
      },
      {
        key: 'fromDate',
        text: 'From Date',
        value: 'fromDate',
        page: ['Task', 'Review'],
      },
      {
        key: 'toDate',
        text: 'To Date',
        value: 'toDate',
        page: ['Task', 'Review'],
      },
      {
        key: 'taskStatus',
        text: 'Task Status',
        value: 'taskStatus',
        page: ['Task'],
      },
      {
        key: 'taskDefKey',
        text: 'Task Type',
        value: 'taskDefKey',
        page: ['Task'],
      },
      {
        key: 'selectedReviewStatus',
        text: 'Review Status',
        value: 'selectedReviewStatus',
        page: ['Review'],
      },
      {
        key: 'findings',
        text: 'Findings',
        value: 'findings',
        page: ['Review', 'Task'],
      },
      {
        key: 'selectedFindingTypes',
        text: 'Finding Types',
        value: 'selectedFindingTypes',
        page: ['Review'],
      },
      {
        key: 'selectedCitation',
        text: 'Citations',
        value: 'selectedCitation',
        page: ['Review'],
      },
      {
        key: 'disallowance',
        text: 'Disallowance',
        value: 'disallowance',
        page: ['Review'],
      },
    ];
    if (props.page) {
      return filter(options, opt => {
        if (includes(opt.page, props.page)) return opt;
      });
    }
  };

  const filterModal = () => {
    return (
      <AmsModal
        size="small"
        centered={false}
        closeOnDimmerClick={false}
        onClose={closeModal}
        open={showFilterModal}
        className="ams-semantic-modal-fix"
      >
        <Header content="Select Filters" />
        <Modal.Content>
          <Modal.Description>
            <Form
              noValidate
              onSubmit={e => {
                console.log('submit');
              }}
            >
              <Form.Field>
                <AmsFormLabel
                  name="Select one or many filters from the dropdown"
                  fieldLabel="filter"
                >
                  <Dropdown
                    width={5}
                    placeholder="Select one or many filters from the dropdown"
                    onChange={(e, { name, value }) => {
                      let filter = value.map(val => {
                        return { key: val, value: true };
                      });
                      setModalFilters(filter);
                    }}
                    value={
                      modalFilters &&
                      // eslint-disable-next-line array-callback-return
                      modalFilters.map(item => {
                        if (item.value) return item.key;
                      })
                    }
                    options={filterOptions()}
                    search
                    multiple
                    selection
                    openOnFocus={false}
                    id="filter"
                    aria-labelledby="filter"
                  />
                </AmsFormLabel>
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {
            <Button
              onClick={() => {
                setShowFilterModal(false);
                setSelectedFilters(modalFilters);
              }}
              content="Submit"
              primary
            />
          }
          {<Button onClick={closeModal} content="Cancel" />}
        </Modal.Actions>
      </AmsModal>
    );
  };

  return (
    <div>
      {filterModal()}
      {renderFilters()}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    fetchedAssigneesFilterList: state.report.fetchedAssigneesFilterList,
    fomUsers: state.users.fomUsers,
  };
};

export default connect(mapStateToProps, {
  fetchAssigneesFilter,
  fetchFOMUsers,
})(SummaryFilters);
