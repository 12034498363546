import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';

// Import actions
import { fetchCitations } from '../../actions/reviewActions';

class ReviewCitationsPage extends Component {
  state = {
    roleType: '',
    submitter: '',
    submitterOptions: [],
    citations: [],
  };

  componentDidMount = () => {
    this.props.fetchCitations({});
  };

  // componentWillReceiveProps = nextProps => {
  //   if (nextProps.citations) {
  //     this.setState({ citations: nextProps.citations.citations });
  //   }
  // };

  renderTable = () => {
    const { citations } = this.props;
    const citationNumber = this.props.location.query.citation;

    const columns = [
      {
        isKey: true,
        dataField: 'standardNumber',
        text: 'Citation Number',
        sort: false,
        headerStyle: { width: '10%' },
      },
      {
        dataField: 'standardText',
        text: 'Citation Text',
        sort: false,
        headerStyle: { width: '60%' },
      },

      {
        dataField: 'affiliatedPerformanceMeasure',
        text: 'Affiliated Performance Measure',
        headerStyle: { width: '30%' },
        sort: false,
      },
    ];

    return (
      <div className="table-container">
        <BootstrapTable
          ref="table"
          trClassName="clickable"
          keyField="standardNumber"
          columns={columns}
          data={
            citations
              ? citations.citations?.filter(
                  cit => cit.standardNumber === citationNumber
                )
              : []
          }
          headerWrapperClasses="table-header"
          hover
          condensed
          bordered={false}
          search
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        <div>
          <hr />
        </div>
        {this.renderTable()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.user,
    citations: state.review.citations,
  };
}

ReviewCitationsPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { fetchCitations })(
  ReviewCitationsPage
);
